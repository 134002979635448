import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import VideoModal from "../components/video-modal";
import {
  screen,
  siteWidth,
  innerWidth,
  mainWhite,
  transHover,
  highlight,
} from "../components/common/variables";
import { getVimeoId } from "../functions";
import { IoIosPlay } from "react-icons/io";
import ReactPlayer from "react-player";

const Wrapper = styled.div`
  padding-top: 59px;
  @media ${screen.medium} {
    padding-top: 80px;
  }

  .hero-container {
    position: relative;
    max-width: ${siteWidth};

    &__iframe {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      max-width: ${siteWidth};
      transform: translate(-50%, -50%);
      overflow: hidden;
      background: #000000;

      .iframe {
        width: 100vw !important;
        height: 56.25vw !important;
        min-height: 100vh;
        min-width: 177.77vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }

      .overlay {
        background-color: rgba(0, 0, 0, 0.45);
        position: absolute;
        width: 100%;
        z-index: 110;
        height: 100%;

        .texts {
          position: absolute;
          bottom: 42px;
          z-index: 99;
          display: flex;
          max-width: ${innerWidth};
          left: 50%;
          transform: translate(-50%);
          width: 100%;
          padding-left: 25px;
          padding-right: 25px;
          flex-direction: column;
          @media ${screen.small} {
            padding-left: 40px;
            padding-right: 40px;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            bottom: 68px;
          }
          @media ${screen.medium} {
            bottom: 100px;
            padding-left: 50px;
            padding-right: 50px;
          }
          @media ${screen.xlarge} {
            padding-left: 0;
            padding-right: 0;
            bottom: 110px;
          }

          &__site-title {
            color: ${mainWhite};
            font-size: 2rem;
            @media ${screen.small} {
              font-size: 2.9rem;
            }
            @media ${screen.medium} {
              font-size: 2.9rem;
            }
            @media ${screen.large} {
              font-size: 4.4rem;
            }
            @media ${screen.xlarge} {
              font-size: 5rem;
            }

            &--subtitle {
              display: block;
              font-size: 1rem;
              margin-top: 5px;
              line-height: 1.25;
              white-space: pre-line;
              font-weight: 300;
              max-width: 600px;
              @media ${screen.small} {
                font-size: 1.5rem;
                margin-top: 10px;
              }
              @media ${screen.medium} {
                font-size: 1.6rem;
              }
              @media ${screen.large} {
                font-size: 1.8rem;
              }
              @media ${screen.xlarge} {
                font-size: 2rem;
              }
            }
          }

          .video-title {
            color: ${mainWhite};
            max-width: 94px;
            border-bottom: 2px ${mainWhite} solid;
            padding-bottom: 10px;
            margin-top: 5px;
            font-size: 1.2rem;
            @media ${screen.small} {
              max-width: 300px;
              border-bottom: 2px ${mainWhite} solid;
              font-size: 1.5rem;
              font-weight: 100;
              margin-top: 0;
            }
            @media ${screen.medium} {
              font-size: 1.4rem;
            }
            @media ${screen.large} {
              font-size: 1.5rem;
            }

            &--year {
              display: block;
            }
          }
        }
      }

      .play-icon {
        color: ${mainWhite};
        position: absolute;
        font-size: 3.9rem;
        top: 50%;
        left: 50%;
        z-index: 2001;
        transform: translate(-50%, -50%);
        transition: ${transHover};
        @media ${screen.small} {
          font-size: 4rem;
          top: 46%;
          top: calc(50% + 70px);
        }
        @media ${screen.medium} {
          top: calc(50% + 100px);
        }
        @media ${screen.large} {
          font-size: 6rem;
          top: calc(50% + 110px);
        }
        @media ${screen.xlarge} {
          font-size: 8rem;
        }
        &:hover {
          color: ${highlight};
        }

        svg {
          cursor: pointer;
        }
      }
    }

    &__overlay {
      background-color: rgba(0, 0, 0, 0.45);
      background: red;
      position: absolute;
      z-index: 110;
      top: 50%;
      left: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 30px;
      padding-left: 25px;
      padding-right: 25px;
      transform: translate(-50%, -50%);
      @media ${screen.small} {
        display: block;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  // hide/show modal state
  const [showModal, setModal] = useState(false);

  const {
    background_video_url,
    subheading,
    title_tag,
    meta_description,
  } = data.content.data;

  const bannerVimeoId = getVimeoId(background_video_url);
  const bannerVideoURL = `https://vimeo.com/${bannerVimeoId}`;
  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="hero-container">
          <div className="hero-container__iframe">
            <ReactPlayer
              className="iframe"
              url={bannerVideoURL}
              loop={true}
              playing={true}
              muted
              config={{
                vimeo: {
                  fullscreen: 0,
                },
              }}
            />

            <span className="play-icon">
              <IoIosPlay onClick={() => setModal(true)} />
            </span>

            <div className="overlay">
              <div className="texts">
                <h1 className="texts__site-title">
                  <span className="texts__site-title texts__site-title--subtitle">
                    {subheading}
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <VideoModal
            videoURL={bannerVideoURL}
            showModal={showModal}
            setModal={setModal}
          />
        )}
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        subheading
        background_video_url
        title_tag
        meta_description
      }
    }
  }
`;
